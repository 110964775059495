import React from "react";
import { Link, useLocation } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import Footer from "../Footer/Footer";
import "./CryptoSell.scss";
import ThemeButton from "../ThemeButton/ThemeButton";
import WalletNotice from "../WalletNotice/WalletNotice";
import WalletAddress from "../WalletAddress/WalletAddress";

export default function CryptoSell() {
  const location = useLocation();
  const { item } = location.state || {};
  // console.log(item)
  return (
    <Dashboard>
      <div className="main-content crytoquicksell ml-250">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9">
              <div className="profile-info-card pb-0">
                <div className="profile-completion d-flex align-items-end justify-content-between">
                  <div className="d-flex align-items-center gap-3">
                    <div>
                      <h1 className="fs-3">Crypto Quick Sell</h1>
                      <span className="fs-6 fw-lighter">
                        Select a crypto to generate your dedicated address
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9">
              <div className="cqswa-box">
                <div className="d-lg-flex d-grid gap-4 justify-content-lg-between align-items-start">
                  <div className="">
                    <h2 className="fs-4">{item?item.blockchain:''} ({item?item.crypto_code:''})</h2>
                    <div className="d-flex gap-2 align-items-center mt-1">
                      <img src={item?item.crypto_icon:''} alt={item?item.blockchain:''} />
                      <p className="fs-6">NGN {item?item.naira_rate:''}</p>
                    </div>
                  </div>
                  {item.is_wallet_created === 1 ?(<>
                    <div className="cqs-trans">
                      <Link to="/user/transcation">
                        <button className="d-flex gap-2 align-items-center justify-content-center w-100">
                          <i class="fa-solid fa-credit-card"></i>
                          <span className="text-white fs-6 d-block">
                            View Transactions
                          </span>
                        </button>
                      </Link>
                    </div>
                  </>):(<></>)}
                 
                </div>
                <div className="mt-3 border-top pt-3">
                  <h3 className="fs-6">
                    Our Tips <i class="fa-solid fa-angle-down"></i>
                  </h3>
                  <p>
                    {item?item.rateText:''}
                  </p>
                </div>
                <div className="mt-3 mt-3 border-bottom pb-3 border-top pt-3">
                  <h3 className="fs-6">
                    About {item?item.blockchain:''} ({item?item.crypto_code:''}) <i class="fa-solid fa-angle-down"></i>
                  </h3>
                  <p>
                  {item?item.crypto_description:''}
                  </p>
                </div>
                <div className="mt-2">
                  {item.is_wallet_created === 1?(<>
                    {["bottom"].map((placement, idx) => (
                    <WalletAddress
                      key={idx}
                      placement={placement}
                      name={item?item:''}
                      button={`Show Wallet Address`}
                    />
                  ))}
                  </>):(<>
                    {["bottom"].map((placement, idx) => (
                      <WalletNotice
                        key={idx}
                        placement={placement}
                        name={item?item:''}
                      />
                    ))}
                  </>)

                  }
                  
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Dashboard>
  );
}
