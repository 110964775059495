import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Success.scss";
import { Link, useNavigate } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import ThemeButton from "../../ThemeButton/ThemeButton";
import Confetti from "react-confetti";

export default function Success(props) {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    props.setShow(false);
    if (props.setShow1) {
      props.setShow1(true);
    }
    if (props.message) {
      props.setSuccess(false);
      navigate(props.pageurl);
    } else {
      props.setSuccessShow(false);
      props.setAmount("");
      props.setPassword("");
    }
  };

  const handleShow = () => setShow(true);

  const handleDone = () => {
    setShow(false);
    props.setShow(false);
    if (props.setShow1) {
      props.setShow1(true);
    }
    if (props.message) {
      props.setSuccess(false);
      navigate(props.pageurl);
    } else {
      props.setSuccessShow(false);
      props.setAmount("");
      props.setPassword("");
    }
  };
  return (
    <>
      <Button className="w-100 d-none" onClick={handleShow}>
        Successfully
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        className="success-popup"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div>
          {/* <Confetti style={{width: "100%", height: "100%"}}/>  */}
          <Modal.Header closeButton className="border-0 pb-0">
            <Modal.Title className="text-center"></Modal.Title>
          </Modal.Header>
          <div className="d-flex d-lg-block justify-content-center h-100">
            <Modal.Body className="pt-2 pb-4">
              <div className="success-info">
                <div>
                  <img src={`/images/${props.img}`} alt="success-icon" />
                  <h2 className={`text-center mt-1 mb-2 ${props.amount}`}>
                    Congrats 
                  </h2>
                  <div className="text-center mb-2">
                    {/* <p>You have successfully Withdrawn ₦ {props.amount} into your account</p> */}
                    {props.message ? (
                      props.message
                    ) : (
                      <>
                        <p>
                          You have successfully Withdrawn ₦ {props.amount} into
                          your account
                        </p>
                      </>
                    )}
                  </div>
                </div>
                <button className="theme-btn" onClick={handleDone}>
                  Done
                </button>
                {/* <ThemeButton btnName="Done" /> */}
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
}
