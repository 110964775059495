import React from "react";
import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./WalletAddress.scss";
import ThemeButton from "../ThemeButton/ThemeButton";
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from 'react-qr-code';
import { useNavigate } from "react-router-dom";


export default function WalletAddress({ name,button, ...props }) {

  const [show, setShow] = useState(false);
  const auth = JSON.parse(localStorage.getItem("user"));
  const [cryptoid,setCryptoID] = useState(name?name.id:'');
  const [cryptoAddress,setCryptoAddress] = useState();
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    navigate('/user/cryptoquicksell')
  }

  const handleShow = async () => {
    if(name.is_wallet_created === 1){
      setShow(true)
      return false;
    }
    setLoading(true)
    try {
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.generateCryptoAddress(cryptoid,token)
      setCryptoAddress(response.data.data.address)
      setLoading(false)
      setShow(true)

    } catch (error) {
      if (error.response) {
        setLoading(false)
        console.log(error.data.data.message)
      } else if (error.request) { 
        console.error('Error request:', error.request);
        setLoading(false)
      } else {
        console.error('Error message:', error.message);
        setLoading(false)
      }
    }
  };

  const handleCopy = () => {
    toast('Text copied to clipboard!');
  };

  return (
    <>
      <span onClick={handleShow}>
        <ThemeButton btnName={button} loading={loading}/>
      </span>
      <Offcanvas
        className="walletaddressoffcanvas"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header
          closeButton
          className="mt-2 pt-2 pb-0"
        ></Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
              <div className="walletaddressinfo-box text-white">
                <h2 className="text-center mt-1">Wallet Address</h2>
                <p className="approved text-info">
                  Copy your assigned wallet address
                </p>
                <div>
                  <img
                    className="notice-img my-2"
                    src="/images/walletqr.svg"
                    alt="Wallet QR"
                  />
                </div>
                <div className="status-card p-3">
                  <div className="row g-lg-3 align-items-center justify-content-center">
                    <div className="col-lg-9">
                    <div className="d-flex align-items-start gap-2">
                    <i class="fa-solid fa-circle-exclamation mt-1"></i>
                    <p>
                      {name?name.disclaimer:''}
                    </p>
                  </div>
                      <div className="qrcode-text text-center">
                        <p className="mb-0">
                          {name?name.crypto_address != null && name.crypto_address : ""}{" "}
                          {cryptoAddress != null && cryptoAddress}
                          <CopyToClipboard text={cryptoAddress?cryptoAddress:name.crypto_address} onCopy={handleCopy}>
                            <i class="fa-regular fa-copy"></i>
                          </CopyToClipboard>
                        </p>
                      </div>
                    </div>
                    <div className="col-6 col-lg-3">
                      <div className=" d-grid justify-content-center">
                      <QRCode
                          value={cryptoAddress?cryptoAddress:name.crypto_address}
                          size={120}
                          bgColor='#FFFFFF'
                          fgColor='#000000'     
                          style={{ marginTop: '20px' }}
                      />
                        {/* <img src="/images/qrcode.png" alt="QR" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-grid gap-2">
                <div className="w-100">
                <CopyToClipboard text={cryptoAddress?cryptoAddress:name.crypto_address} onCopy={handleCopy}>
                    <button className='theme-btn'>
                      Copy Wallet Address
                      </button>
                </CopyToClipboard>

                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
