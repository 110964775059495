import React, { useEffect, useState } from "react";
import Dashboard from "../Dashboard/Dashboard";
import Footer from "../Footer/Footer";
import "./CashBackWallet.scss";
import TransactionCard from "../TransactionCard/TransactionCard";
import LoginSignService from '../../Services/LoginSignService/LoginSignService';
import RequestWithdrawal from "../Modals/RequestWithdrawal/RequestWithdrawal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DNA } from 'react-loader-spinner'
import { ColorRing } from 'react-loader-spinner'
import BlankCard from '../BlankCard/BlankCard';

export default function CashBackWallet() {

 

  const auth = JSON.parse(localStorage.getItem("user"));
  const [walletHistory,setWalletHistory] = useState("");
  const [minwithdwalLimit,setminwithdwalLimit] = useState("");
  const [maxwithdwalLimit,setmaxwithdwalLimit] = useState("");
  const [withDrawalHistory,setWithDrawalHistory] = useState([]);
  const [walletCurrency,setWalletCurrency] = useState([]);
  const [amount,setAmount] = useState("");
  const [password,setPassword] = useState("");
  const [loading,setLoading] = useState(false);
  const [successShow,setSuccessShow] = useState(false);
  const [loader,setLoader] = useState(false)
  const [loaderBalance,setLoaderBalance] = useState(false)

  const [show1,setShow1] = useState(false);

  // Get Balance 
  const getBal = async ()=>{   
    setLoaderBalance(true) 
    try {
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.getBalance(2,token)
      setWalletHistory(response.data.data.wallet_amount);
      setminwithdwalLimit(response.data.data.min_withdrawable_amount)
      if(response.data.data.kycStatus == 0)
      {
        setmaxwithdwalLimit(response.data.data.max_withdrawable_amount_without_KYC??'')
      }
      setLoaderBalance(false) 

    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response);
        setLoaderBalance(false) 

      } else if (error.request) { 
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  }

   // Get WithDrawal History 
   const getWith = async ()=>{  
    setLoader(true)  
    try {
      const token = (auth.data.item.access_token);  
      const response = await LoginSignService.withDrawalHistory(2,token)
       setWithDrawalHistory(response.data.data);
       setLoader(false)  

    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response);
        setLoader(false)  

      } else if (error.request) { 
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  }

  useEffect(()=>{
      getBal();
      getWith();
  },[])

  // Handle Request
  const handleRequest = async (e) =>{
    e.preventDefault();

    let data = {amount:amount,password:password,walletTypeId:2,platform:'web'}
    if(validate())
    {
      try {
        setLoading(true);
        const token = (auth.data.item.access_token); 
        const response = await LoginSignService.withdrawBalance(data,token)
        //  setAmount("")
        //  setPassword("")
        getWith();
        getBal();
        setSuccessShow(true);
        setShow1(false)
        setLoading(false);

        //  toast.success(response.data.message)
         
      } catch (error) {
        if (error.response) {
          setLoading(false);
          console.error('Error response:', error.response.data.message);
          toast.error(error.response.data.message)
        } else if (error.request) { 
          setLoading(false);
          console.error('Error request:', error.request);
        } else {
          setLoading(false);
          console.error('Error message:', error.message);
        }
      }
    }
  }

  // Validate 
  const validate =()=>{
   
    let result = true;
    if(!amount)
    {
      toast.error("Please enter amount");
      return result = false;
    }
    if(Number(amount) < Number(minwithdwalLimit))
    {
      toast.error("Withdraw amount should not be less than 1000.");
      return result = false;
    }
    // if(maxwithdwalLimit)
    // {
    //     if(Number(amount) > Number(maxwithdwalLimit))
    //     {
    //       toast.error(`Maximum amount ${maxwithdwalLimit} debit`);
    //       return result = false;
    //     }
    // }
    if(Number(amount) > Number(walletHistory))
    {
      toast.error(`Insufficient funds! You can withdraw at max ${walletHistory}`);
      return result = false;
    }
    if(!password)
    {
      toast.error("Please enter password");
      return result = false;
    }
    return result;
  }

  return (
    <>
      <Dashboard>
        <div className="main-content cashbackwallet ml-250">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-9">
                <div className="profile-info-card pb-0">
                  <div className="profile-completion d-flex align-items-end justify-content-between">
                    <div className="d-flex align-items-center gap-3">
                      <div>
                        <h1 className="fs-3">Cashback Wallet</h1>
                        <span className="fs-6 fw-lighter">
                          Get cash back on every transaction you make!
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="wallet-balance position-relative z-2">
                    <div className="wb-overlay"></div>
                    <div className="row align-items-center">
                      <div className="col-lg-8">
                        <h5>
                          <i class="fa-solid fa-wallet" /> Overall Balance
                        </h5>
                        <div className="d-grid gap-4 align-items-center">
                          <div>
                            {
                              loaderBalance?(<>
                                    <ColorRing
                                      visible={true}
                                      height="80"
                                      width="80"
                                      ariaLabel="color-ring-loading"
                                      wrapperStyle={{}}
                                      wrapperClass="color-ring-wrapper"
                                      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                    />
                              </>):(<>
                                <h4 className="fs-3 fs-lg-1">₦ {walletHistory?walletHistory:0}</h4>
                                {
                                  walletHistory >= minwithdwalLimit ?(<>
                                  </>):(<>
                                    <p className='low-balance-alart text-danger mt-2'><i className="fa-solid fa-circle-info"/> Your balance is too low to initiate a withdrawal process</p>
                                  </>)
                                }
                              </>)
                            }
                            
                            
                          </div>
                        </div>
                        <div>
                    </div>
                      </div>
                      <div className="col-lg-4 mt-4 mt-lg-0">
                        <div className="">
                          {
                           walletHistory >= minwithdwalLimit ?(<>
                             <RequestWithdrawal handleRequest={handleRequest} setAmount={setAmount} setPassword={setPassword} amount={amount} password={password} loading={loading} setSuccessShow={setSuccessShow} successShow={successShow} setShow1={setShow1} show1={show1}/>
                           </>):(<>
                            <button className="btn-low-balance btn w-100 text-danger bs-danger-bg-subtle">
                            <span><i class="fa-solid fa-money-bill-wave"/> Balance Low</span>
                           </button>
                           </>)

                          }
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="recent-activity mt-4 mt-lg-0">
              <div className="row justify-content-center">
                <div className="col-sm-12 col-md-12 col-lg-9">
                  <div className="d-flex justify-content-between align-items-center">
                    <h3>Recent Activity</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="bitcoin-box">
              <div className="row justify-content-center">
                <div className="col-sm-12 col-md-12 col-lg-9">
                  {
                    loader?(<>
                        <div className="row">
                          <div className="col-md-12 text-center">
                          <DNA
                              className="text-center"
                              visible={true}
                              height="80"
                              width="80"
                              ariaLabel="dna-loading"
                              wrapperStyle={{}}
                              wrapperClass="dna-wrapper"
                            />
                          </div>
                        </div>  
                    </>):(<>
                    {
                      withDrawalHistory.length>0?(<>
                      {withDrawalHistory.map((item)=>(
                        <TransactionCard name={item.transactionType} tag={item.status} amount={`₦ ${item.amount}`} date={`${item.date}`} time={`${item.time}`}
                        bankname={item.bankName}
                        accountname={item.accountName}
                        accountnumber={item.accountNumber}
                        />
                      ))}
                      </>):(<>
                      <BlankCard/>
                      </>)
                    }
                      
                    </>)
                  }
                
                
                 
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Dashboard>
      <ToastContainer/>
    </>
  )
}
