// dateUtils.js
export const convertTimestampToDate = (timestamp) => {
    // Convert timestamp to milliseconds
    const date = new Date(timestamp * 1000);

    // Extract day, month, year, hours, minutes, and seconds
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0'); // Ensure two digits
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Ensure two digits
    const seconds = String(date.getSeconds()).padStart(2, '0'); // Ensure two digits

    // Format as dd-mm-yyyy hh:mm:ss
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};