import React, { useEffect, useState } from "react";
import Dashboard from "../Dashboard/Dashboard";
import "./Notification.scss";
import Footer from "../Footer/Footer";
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import { convertTimestampToDate } from "../../Services/LoginSignService/dateUtils";
import { DNA } from "react-loader-spinner";
import BlankCard from "../BlankCard/BlankCard";

export default function Notification() {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getNotifications();
  }, []);

  // Notification API
  const getNotifications = async () => {
    setLoader(true);
    try {
      const token = auth.data.item.access_token;
      const response = await LoginSignService.getNotification(token);
      setList(response.data.data);
      setLoader(false);
    } catch (error) {
      if (error.response) {
        setLoader(false);

        console.log(error.data.data.message);
      } else if (error.request) {
        setLoader(false);

        console.error("Error request:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };

  return (
    <Dashboard>
      <div className="main-content notify-box ml-250">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="profile-info-card pb-0">
                <div className="profile-completion d-flex align-items-end justify-content-between">
                  <div className="d-flex align-items-center gap-3">
                    <div>
                      <h1 className="fs-3">Notifications</h1>
                      <span class="fs-6 fw-lighter">
                        Lets catch up with your ongoing processes
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {loader ? (
                <>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <DNA
                        className="text-center"
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {list.length > 0 ? (
                    <>
                    <div className="notifcation-card">
                      {list.map((item) => (
                        <>
                          
                            <div className="notify-card">
                              <div className="d-flex gap-2 align-items-start">
                                <img
                                  src="/images/Withdrawal.svg"
                                  alt="Notification"
                                />
                                <div className="d-grid w-100">
                                  <h2>{item.title}</h2>
                                  <p>{item.message}</p>
                                  <span>
                                    {convertTimestampToDate(item.timestamp)}
                                  </span>
                                </div>
                              </div>
                            </div>
                        </>
                      ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <BlankCard />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Dashboard>
  );
}
