import React from "react";
import CryproHistory from "../CryptoHistory/CryptoHistory";

export default function TransactionCrypto(props) {
  const formattedValue = Number(props.received).toFixed(4);
  return (
    <>
      <div className="transcryptocard">
        <div className="inner-bitcoin d-flex justify-content-between align-items-start">
          <div className="d-flex align-items-start gap-2">
            <div className="bitcoin-icon">
              <img src={props.icon} alt="" width="40px"/>
            </div>
            <div className="text-bitcoin">
              <div className="d-flex gap-1 align-items-start">
                <h4>{formattedValue} {props.name}</h4>
                <button>
                  {["bottom"].map((placement, idx) => (
                    <CryproHistory
                      key={idx}
                      placement={placement}
                      name={placement}
                      crypto_currency={props.crypto_currency}
                      transactionID={props.transactionID}
                      network={props.network}
                      date={props.date}
                      time={props.time}
                      status={props.tag}
                      received={props.received}
                      nairavalue={props.amount}
                      usdvalue={props.usd_exchange}
                      trandingrate={props.trandingrate}
                    />
                  ))}
                </button>
              </div>
              <h5 className="fs-6 mb-2">{props.status}</h5>
              <h6>{props.tag}</h6>
            </div>
          </div>
          <div className="bitcoin-amount">
            <p>{props.amount}</p>
            <span>
              {props.date}
              <span className="d-block ms-lg-1 d-lg-inline-block">{props.time}</span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
