import React, { useEffect } from "react";
import Dashboard from "../Dashboard/Dashboard";
import Footer from "../Footer/Footer";
import TransactionCard from "../TransactionCard/TransactionCard";
import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./Transcation.scss";
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import TransactionCrypto from "../TranscationCrypto/TranscationCrypto";
import { DNA } from 'react-loader-spinner'
import BlankCard from '../BlankCard/BlankCard';

export default function Transcation() {
  const [key, setKey] = useState("home");
  const auth = JSON.parse(localStorage.getItem('user'))
  const [list,setList] = useState([]);
  const [withList,setWithList] = useState([]);
  const [loader,setLoader] = useState(false)

  useEffect(()=>{
    getCryptoTran();
    getWithHis();
  },[])

  //Get Crypto Transaction
  const getCryptoTran = async()=>{
    try {
      setLoader(true);
      const data = {};
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.getCryptoTransaction(data,token)
      setList(response.data.data)
      setLoader(false);

    } catch (error) {
      if (error.response) {
        console.log(error.data.data.message)
        setLoader(false);

      } else if (error.request) { 
        console.error('Error request:', error.request);
        setLoader(false);

      } else {
        console.error('Error message:', error.message);
      }
    }
  }

  //Get WithDrawalHistory Transaction
  const getWithHis = async()=>{
    try {
      setLoader(true);
      const data = "";
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.withDrawalHistory(data,token)
      setWithList(response.data.data)
      setLoader(false);

    } catch (error) {
      if (error.response) {
        console.log(error.data.data.message)
        setLoader(false);

      } else if (error.request) { 
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  }

  
  return (
    <>
      <Dashboard>
        <div className="main-content transcation ml-250">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-9">
                <div className="profile-info-card pb-0">
                  <div className="profile-completion d-flex align-items-end justify-content-between">
                    <div className="d-flex align-items-center gap-3">
                      <div>
                        <h1 className="fs-3">Transaction</h1>
                        <span className="fs-6 fw-lighter">
                          View pertinent details of transactions carried out
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9">
            <div className="trans-tab mt-0 mt-lg-4">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="home" title="Crypto">
                  <div className="bitcoin-box">
                    <div className="row justify-content-center">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        {
                          loader?(<>
                             <div className="row">
                          <div className="col-md-12 text-center">
                          <DNA
                              className="text-center"
                              visible={true}
                              height="80"
                              width="80"
                              ariaLabel="dna-loading"
                              wrapperStyle={{}}
                              wrapperClass="dna-wrapper"
                            />
                          </div>
                        </div>  
                          </>):(<>
                            {
                          list.length>0?(<>
                            {
                              list.map((item)=>(
                                <>
                                  
                                   <TransactionCrypto
                                      name={item.crypto_currency}
                                      status="Deposit"
                                      tag={item.status}
                                      amount={`₦ ${item.Naira_credited_amount}`}
                                      date={item.date}
                                      time={item.time}
                                      crypto_currency={item.crypto_currency}
                                      transactionID={item.transaction_id}
                                      network={item.network}
                                      received={item.received_crypto_amount}
                                      usd_exchange={item.usd_exchange}
                                      trandingrate={item.nairaRate}
                                      icon={item.crypto_icon}
                                    />
                                </>
                              ))
                            }
                          </>):<>
                          <BlankCard/>
                          </>
                        }
                          </>)
                        }
                        
                       </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="profile" title="Wallets">
                  <div className="bitcoin-box">
                    <div className="row justify-content-center">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        {
                          loader?(<>
                            <div className="row">
                          <div className="col-md-12 text-center">
                          <DNA
                              className="text-center"
                              visible={true}
                              height="80"
                              width="80"
                              ariaLabel="dna-loading"
                              wrapperStyle={{}}
                              wrapperClass="dna-wrapper"
                            />
                          </div>
                        </div>  
                          </>):(<>
                            {
                          withList.length>0?(<>
                          {
                            withList.map((item)=>(
                              <>
                             
                               <TransactionCard
                                  name={item.transactionType}
                                  tag={item.status}
                                  amount={`₦ ${item.amount} `}
                                  date={item.date}
                                  time={item.time}
                                  bankname={item.bankName}
                                  accountname={item.accountName}
                                  accountnumber={item.accountNumber}
                                />
                              </>
                            ))
                          }
                          </>):<><BlankCard/></>
                        }
                          </>)
                        }
                       
                        
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
            </div>
            </div>
            <Footer />
          </div>
        </div>
      </Dashboard>
    </>
  );
}
