import React, { useEffect } from "react";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./PaymentDetails.scss";
import ThemeButton from "../ThemeButton/ThemeButton";
import LoginSignService from '../../Services/LoginSignService/LoginSignService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Success from "../Modals/Success/Success";
import Select from 'react-select';

export default function PaymentDetails({ name, ...props }) {
  const [show, setShow] = useState(false);
  const auth = JSON.parse(localStorage.getItem("user"));
  const [redBillerBankL,setRedBillerBankL] = useState([]);
  const [noRedBillerBankL,setNoRedBillerBankL] = useState([]);
  const [acNumber, setAccountNumber] = useState("");
  const [acName, setAccountName] = useState("");
  const [password, setPassword] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [isReadonly, setIsReadonly] = useState(false);
  const [checkRed, SetCheckRed] = useState("");
  const [bank, setSelects] = useState("");
  const [inpuloader, setInputLoading] = useState(false);
  const [error, setError] = useState();
  const [loading,setLoading] = useState(false);
  const [bankD,setBankD] = useState("");
  const [success,setSuccess] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [bankL, setBankL] = useState([]);
  const [selectedOption, setSelectedOption] = useState('--- BITCOIN ---');
  
   
  useEffect(()=>{
    bankList()
    paymentDetails()
  },[])

  // Get Bank List
  const bankList = async ()=>{
    try {
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.getBankList(token)
      setNoRedBillerBankL(response.data.data)
      setRedBillerBankL(response.data.data1)
      if(response.data.data)
      {
        let finalArr = [];
        let arr = [{ label: 'Select Bank', value: 'Any', disabled: true }]
        let arr1 = response.data.data.map((item) => ({
          label: item.bank_name,
          value: item?.bank_name,
          dataRed:item.is_redbiller,
          dataBankCode:item.bank_code

        }));
        let arr2 = response.data.data1.map((item) => ({
          label: item.bank_name,
          value: item?.bank_name,
          dataRed:item.is_redbiller,
          dataBankCode:item.bank_code

        }));
        let finalArr1 = arr.concat(arr1);
        finalArr = finalArr1.concat(arr2)
        setBankL(finalArr)
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.message)
      } else if (error.request) { 
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  }

  // Get paymentDetails 
  const paymentDetails = async ()=>{
    try {
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.getPaymentDetail(token)
      setAccountNumber(response.data.data.accountNumber)
      setAccountName(response.data.data.accountName)
      setSelects(response.data.data.bankName)
      setBankCode(response.data.data.bankCode)
    } catch (error) {
      if (error.response) {
        console.log(error.data)
      } else if (error.request) { 
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  }

  // Update Payment Details 
  const handlePaymentDetails =async (e)=>{
    e.preventDefault();
    let value = {bank:bank,bankcode:bankCode,account_name:acName,account_number:acNumber,password:password}
    if(validate()){
      setLoading(true)
      try {
        const token = (auth.data.item.access_token); 
        const response = await LoginSignService.updateBankDetails(value,token)
        setAccountNumber("")
        setAccountName("")
        setPassword("")
        setBankCode("")
        setLoading(false)
        setShow(false)
        paymentDetails()
        setSuccess(true)
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message)
          toast(error.response.data.message)
          setError(error.response.data.message)
          setLoading(false)

        } else if (error.request) { 
          console.error('Error request:', error.request);
          setLoading(false)

        } else {
          console.error('Error message:', error.message);
          setLoading(false)

        }
      }
    }
  }

  const handleChange = (event) => {
    // alert(event.value);
    setSelectedOption(event)
    // setSelects(event);
    // alert(event.dataRed)
    // alert(event.dataBankCode)
    setAccountName("");
    setAccountNumber("");
    setSelects(event.value);
    const checkRedbiller = event.dataRed;
    const bcode = event.dataBankCode;
      setBankCode(bcode);
      SetCheckRed(checkRedbiller);
    if (checkRedbiller == 0) {
      setIsReadonly(false);
    } else {
      setIsReadonly(true);
     
    }
  };


  // handle Select
  const handleSelect = (event) => {
    setAccountName("");
    setAccountNumber("");
    setSelects(event.target.value);
    const checkRedbiller =
      event.target[event.target.selectedIndex].getAttribute("data-red");
    const bcode =
      event.target[event.target.selectedIndex].getAttribute("data-bankCode");
      setBankCode(bcode);
      SetCheckRed(checkRedbiller);
    alert(checkRedbiller)
    if (checkRedbiller == 0) {
      setIsReadonly(false);
    } else {
      setIsReadonly(true);
     
    }
  };

  // account no
  const handleAccountNo = (event) => {
    setAccountNumber(event.target.value);
  };

  const handleV = (event) => {
    const inputValue = event.target.value;
    if(checkRed == 1)
    {
     
      // Allow only numbers
      const numericValue = inputValue.replace(/[^.0-9]/g, '');
      setAccountNumber(numericValue);
      if (acNumber.length == 10 && checkRed == 1) {
        setInputLoading(true);
        getAccountName();
      }
    }else{
      setAccountNumber(inputValue);

    }
    
  };

  // Redbiller Account Name
  const getAccountName = async () => {
    try {
      const token = (auth.data.item.access_token); 
      let bankcode = bankCode;
      let accountno = acNumber;
      //  let bankcode = '000013';
      //  let accountno = '0158541672';
      const response = await LoginSignService.GetRedbillerAccountName(bankcode,accountno,token)
      setAccountName(response.data.data.account_name);
      setInputLoading(false);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.message)
        toast(error.response.data.message)
        setInputLoading(false);

      } else if (error.request) { 
        console.error('Error request:', error.request);
        setInputLoading(false);

      } else {
        console.error('Error message:', error.message);
      }
    }
    
  };

   
   // Validate 
   const validate =()=>{
   
    let result = true;
    if(!bank)
    {
      toast.error("Enter Bank Name");
      return result = false;
    }
    if(!bankCode)
    {
      toast.error("Enter Bankcode");
      return result = false;
    }
    if(!acNumber)
    {
        toast.error(`Enter Account Number`);
        return result = false;
    }
    if(!acName)
    {
      toast.error(`Enter Account Name`);
      return result = false;
    }
    
    if(!password)
    {
      toast.error("Please enter password");
      return result = false;
    }
    return result;
  }

  // by default select bank name
  useEffect(()=>{
    const defaultOption = bankL.find(option => option.value === bank);
    setSelectedOption(defaultOption || bankL[0]); // Fallback to first option if not found 
  })
  
  return (
    <>
      {
        success?(<>
         <Success  
          setShow={setShow}
          message = {'Payment details updated successfully'}
          pageurl={'/user/account'}
          setSuccess={setSuccess}
          img="tick.gif"
          />
        </>):""
      }
    
      <span
        onClick={handleShow}
        className="paymentdetailsbtn d-flex gap-2 justify-content-between"
      >
        <span className="d-flex gap-2 align-items-center">
          <i class="fa-solid fa-building-columns"></i>
          <span>Payment Details</span>
        </span>
        <img src="/images/arrow-right.svg" alt="" />
      </span>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <Offcanvas
              className="paymentdetailsoffcanvas"
              show={show}
              onHide={handleClose}
              {...props}
            >
              <Offcanvas.Header
                closeButton
                className="mt-2 pt-2 pb-0"
              ></Offcanvas.Header>
              <Offcanvas.Body className="pt-0">
                <div className="row justify-content-center">
                  <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-6">
                    <div className="paymentdetailsinfo-box text-white">
                      <h2 className="text-center mt-1">Payment Details</h2>
                      {/* <p className="approved text-info">
                    Complete below to update email
                  </p> */}
                      <div className="status-card my-4 p-3">
                        <Form onSubmit={handlePaymentDetails}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Select className="pdsearch"
                                options={bankL}
                                value={selectedOption}
                                onChange={handleChange}
                                placeholder="Select Bank"
                                //  defaultValue={bankL[2]}
                                isSearchable={true} // Enables the search functionality
                            />
                            
                            {/* <Form.Label>Current Password</Form.Label> */}
                            {/* <Form.Select className="w-100" aria-label="Default select example" onChange={handleSelect}>
                              <option>Select</option>
                              {
                                noRedBillerBankL.map((item)=>(
                                  <option
                                  value={item.bank_name}
                                  data-bankCode={item.bank_code}
                                  data-red={item.is_redbiller}
                                  selected={bank == item.bank_name ? "selected" : ""}

                                  >{item.bank_name}</option>
                                ))
                              }
                              {
                                redBillerBankL.map((item)=>(
                                  <option value={item.bank_name}
                                  data-bankCode={item.bank_code}
                                  data-red={item.is_redbiller}
                                  selected={bank == item.bank_name ? "selected" : ""}
                                  >{item.bank_name}</option>
                                ))
                              }
                              
                            </Form.Select> */}
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Account Number"
                              value={acNumber}
                              onChange={handleAccountNo}
                              onKeyUp={handleV}
                            />
                             {inpuloader ? (
                                <>
                                  <div className="text-white">
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    Loading...
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                          </Form.Group>
                          <Form.Group
                            className="mb-4"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Account Name"
                              value={acName}
                              onChange={(e) => setAccountName(e.target.value)}
                              readOnly={isReadonly}
                            />
                            
                          </Form.Group>
                          <Form.Group
                            className="mb-4"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              type="password"
                              placeholder="Enter Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <span className="text-danger">{error}</span>
                          </Form.Group>
                          <ThemeButton btnName="Update" loading={loading} />
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer/>
          </div>
        </div>
      </div>
    </>
  );
}
