import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Camera.scss";
import { Link } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import ThemeButton from "../../ThemeButton/ThemeButton";
import Webcam from "react-webcam";

export default function Camera({setImage}) {
  localStorage.removeItem("kycimage");
  const [show, setShow] = useState(false);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showSuccess,setShowSuccess] = useState(false);
  const FACING_MODE_USER = "user";
  const FACING_MODE_ENVIRONMENT = "environment";
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
  const videoConstraints = {
    facingMode: FACING_MODE_USER
  };

  const switchCamera = React.useCallback(() => {
    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
  }, []);
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      localStorage.setItem("kycimage", imageSrc);
      setImage(imageSrc)
      setShow(false)
      setShowSuccess(true)
    },
    [webcamRef]
  );

  const handleFileChange = (event) => {
    setShow(false)
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file) {
      reader.onloadend = () => {
        // Store base64 string in local storage
        localStorage.setItem("kycimage", reader.result);
        setImage(reader.result)
        setShowSuccess(true)
      };
     reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <div
        className="upload-doc d-grid justify-content-center"
        onClick={handleShow}
      >
        {
          showSuccess?(<>
          <div className="">
            <div className="d-grid">
              <img
                className="success-icon"
                src="/images/success.png"
                alt="success"
              />
              <span className="img-captured text-center mb-3">
                Image is captured
              </span>
            </div>
          </div>
          </>):<>
          <img className="" src="/images/Image upload.svg" alt="Upload image" />
          </>
        }
        <div className="d-flex gap-2 align-items-center inter text-center">
          <span>
            <i class="fa-solid fa-camera" /> PASSPORT/NIN/DRIVER'S LICENSE/NIMC/VOTER'S ID
          </span>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className="camera-popup"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div>
          <Modal.Header closeButton className="border-0 pb-0">
            <Modal.Title className="text-center"></Modal.Title>
          </Modal.Header>
          <div className="d-flex d-lg-block justify-content-center h-100">
            <Modal.Body className="pt-2 pb-4">
              <div className="camera-box">
              <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      videoConstraints={{
                        ...videoConstraints,
                        facingMode
                      }}
                    />

              </div>
              <div className="row justify-content-end">
                <div className="col-12">
                   <div className="d-flex justify-content-between align-items-center">
                      <div>
                      <input
                          type="file"
                          id="file-upload"
                          accept="image/*" // Accept only image files
                          onChange={handleFileChange}
                          style={{ display: 'none' }} // Hide the input
                      />
                      <label htmlFor="file-upload">
                          <i class="fa-solid fa-upload"/>
                          <span>upload</span>
                      </label>
                        
                      </div>
                      
                      <div>
                        <button onClick={capture} className="text-white take-selfie">Take Selfie</button>
                      </div>
                      <div><i onClick={switchCamera} class="fa-solid fa-camera-rotate"/></div>
                   </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
}
