import React from 'react';
import './TermService.scss';
import FrontHeader from '../FrontPage/frontheader';
import FrontFooter from '../FrontPage/frontfooter';


export default function TermService() {
  return (
    <>
    <FrontHeader/>
     <div className="static-page mt-5">
          <div className='container'>
             <div className='row justify-content-center'>
                <div className='col-sm-12 col-md-12 col-lg-10'>
                    <div className='static-card'>
                        {/* <div className='static-image-box'>
                            <img src='/images/static-icon.svg' alt='iocn'/>
                        </div> */}
                        <div className="d-grid gap-0 align-items-center justify-content-center">
                  <img
                    src="/images/terms.png"
                    alt=""
                    style={{ width: "100px", margin: "0 auto 5px" }}
                  />
                  <h2 className='text-center mb-3'>Terms of Service</h2>
                </div>
                        
                        <p><strong>Terms of service are the legal agreements between us( service provider) and you ( a person who wants to use that service)</strong></p>
                        <p>Terms of Service Upon registration on our app or website services, you unconditionally and unequivocally agree and accept the terms and conditions stated hereunder:</p>
                        <ul>
                          <li>You accept not to use QUICKCHAIN products and services to facilitate illegal activities.</li>
                          <li>Information provided on this website does not constitute accounting, legal, financial, consulting, investment or other professional advice</li>
                          <li>You bear all risks from any decision to use QUICKCHAIN and QUICKCHAIN shall not be liable for any loss suffered.</li>
                          <li>Your account may be terminated if it is discovered that you have provided us with false or misleading information, or if you refuse to provide correct information about your true identity.</li>
                          <li>You agree and consent that your account may be suspended pending the outcome of an investigation,</li>
                        </ul>
                        <p>You agree and consent to receive correspondence from Terms of Service Upon registration to use QUICKCHAIN services, you unconditionally and unequivocally agree and accept the terms and conditions stated hereunder:</p>
                        <ul>
                          <li>You accept not to use QUICKCHAIN products and services to facilitate illegal activities.</li>
                          <li>Information provided on this website does not constitute accounting, legal, financial, consulting, investment or other professional advice.</li>
                          <li>You bear all risks from any decision to use QUICKCHAIN and QUICKCHAIN shall not be liable for any loss suffered.</li>
                          <li>Your account may be terminated if it is discovered that you have provided us with false or misleading information, or if you refuse to provide correct information about your true identity.</li>
                          <li>You agree and consent that your account may be suspended pending the outcome of an investigation,</li>
                          <li>You agree and consent to receive correspondence from QUICKCHAIN via email, SMS, push notifications, social media, letters or any other media we use.</li>
                          <li>You are responsible for maintaining adequate security and control of any and all IDs, passwords, personal identification numbers (PINs), or any other codes or credentials that you use to access the services.</li>
                          <li>You are not permitted to sell, borrow, share or otherwise make available your account or any information necessary to access your account to people or entities other than yourself.</li>
                        </ul>
                        <h3>Transactions</h3>
                        <ul>
                          <li>The Bitcoin NETWORK IS RESPONSIBLE FOR THE CONFIRMATION OF BITCOIN transactions. QUICKCHAIN shall not be liable for any loss due to, but not limited to, errors in the protocol, delays or any other breakdown relating to the Bitcoin networkk.</li>
                          <li>You acknowledge that QUICKCHAIN cannot confirm, cancel or reverse coin transactions.</li>
                          <li>You expressly agree that QUICKCHAIN shall not be liable for any errors, loss or omissions due to sending Bitcoin to or receiving Bitcoin from incorrect wallet addresses, e-mail addresses, entering incorrect transaction amounts, or any other information related to coin transactions by using the service. Be sure to use the correct coin addresses, e-mail addresses, transaction amounts when transacting on our platforms.</li>
                          <li>By using QUICKCHAIN you accept the risk that transactions may be delayed and you agree not to hold QUICKCHAIN liable for any damages or loss due to delays.</li>
                          <li>Trade orders placed on QUICKCHAIN are binding and cannot be cancelled or reversed.</li>
                          <li>This service is provided without warranty of any kind and QUICKCHAIN does not guarantee that the website will be available 100% of the time. You acknowledge and accept that the website may become unavailable from time to time due to various circumstances and that QUICKCHAIN will not be liable for any losses due to the system or service glitches.</li>
                          <li>QUICKCHAIN cannot and does not guarantee the value of any traded item and has no obligation to buy back such traded items at any time.</li>
                          <li>You are responsible to pay all applicable transaction FEES</li>
                          <li>We may request for additional verification at anytime if any behaviour or transaction is deemed suspicious by system or admins.</li>
                          <li>Kindly note that for withdrawal methods other than through naira or cedis bank accounts, the applicable exchange rate will be based on the parallel market rate. This rate is the standard at which we process such transactions. We advise our users to be aware of this exchange rate policy when selecting their preferred withdrawal method.</li>
                        </ul>
                        <h3>Investigations</h3>
                        <ul>
                          <li>QUICKCHAIN reserves the right to investigate suspected violations of these terms of service, including without limitation any violation arising from any submission, posting or e-mails you make or send to any forum on the site.</li>
                          <li>QUICKCHAIN may seek to gather information from the user who is suspected of violating these Terms of Service, and from any other user.</li>
                          <li>QUICKCHAIN may suspend any of its users whose conduct or postings are under investigation and may remove such material from its servers as it deems appropriate and without notice.</li>
                        </ul>
                        <h3>Account Monitoring, Suspension and Termination</h3>
                        <p>Your account may be monitored, suspended or terminated in the following circumstances:</p>
                        <ul>
                          <li>Upon probable cause to believe that you have acted in violation of these terms of service.</li>
                          <li>That content or material submitted or shared by you in or through the services violates these terms of service or the intellectual property rights, other property rights or the right to privacy of any third party.</li>
                          <li>We have reason to believe that your use of the services is in violation of any applicable law or regulation, or that you are using the services in a fraudulent manner or otherwise contrary to the purpose of this terms of service.</li>
                          <li>We have reason to believe that you have supplied false, misleading or deceptive information in connection with your registration, identity verification, transactions or any other use of the services, either to us or to another user.</li>
                          <li>You have failed to respond to our inquiries or failed to supply required information requested by us within a reasonable time.</li>
                          <li>In compliance with applicable laws, regulations or order of court as the case may be.</li>
                        </ul>
                        <h3>Payment details</h3>
                        <ul>
                          <li>Valid payment details must be provided through authorised QUICKCHAIN channels/platforms. It is a violation of these terms of service to provide payment details through other unauthorised channels.</li>
                          <li>Buyers must be able to, upon our request, provide adequate proof of payment for at least 180 days after trades have been completed.</li>
                        </ul>
                        <h3>Prohibited Activities</h3>
                        <p>QUICKCHAIN strives to attain and maintain the highest security culture and compliance standards to government laws and regulations as well as protection of our customers. Engaging in any of the prohibited activities specified below may give rise to prosecution by law enforcement agencies. The following acts are prohibited on QUICKCHAIN web, mobile, app or other platforms.</p>
                        <ul>
                          <li>Gathering, stealing or engaging in unauthorised collection of information about users.</li>
                          <li>Changing, amplifying or modifying any part of the site or the services for any purpose whatsoever.</li>
                          <li>Disguising identity or user credentials and use of another user's credentials in engaging with QUICKCHAIN.</li>
                          <li>Alter-engineering, reverse-engineering or otherwise tampering with QUICKCHAIN platform in order to find limitations or vulnerabilities.</li>
                          <li>Covert engineering of products/services for unauthorised purposes.</li>
                        </ul>
                        <h3>Grievance Policy</h3>
                        <p>QUICKCHAIN believes in providing excellent service and strives to be sincere and transparent in its approach to customer service. Your confidence in us is of paramount importance and we would never place our personal growth before your interest. This policy aims to minimize instances of customer complaints, grievances and disputes through a channelized approach of review and redress. Your feedback will help us in identifying shortcomings in our product features and service delivery. Your satisfaction is our main objective in our quest to build a healthy customer relationship and deliver excellent service. QUICKCHAIN has developed a procedure for prompt attention to the grievances of its customers with respect to various issues by setting up a “customer support” and “a dispute resolution mechanism”. The following are the foundational principles of this policy:</p>
                        <ul>
                          <li>Transparency.</li>
                          <li>Prompt, courteous and timeous response to all queries, enquiries, and complaints.</li>
                          <li>Constantly developing new and smarter mechanisms to receive and address customer grievances.</li>
                        </ul>
                        <p>The details of the dispute resolution mechanism are placed in the domain of public knowledge. A dispute may occur as a result of several reasons such as service expectations and service delivery or technical or non-technical errors in the system or due to human inadvertence or error. QUICKCHAIN provides a user-friendly platform to all its customers to file a complaint/ share feedback if they are disappointed by the services rendered. Customers can give their complaint/ feedback by sending an email to our customer support team. If the user’s grievances are not addressed within the stipulated time frame, or if the user is not completely satisfied with the solution provided by QUICKCHAIN then they can approach our dispute resolution system with their complaints.</p>
                    </div>
                </div>
             </div>
          </div>
     </div>
     <FrontFooter/>
    </>
  )
}
