import React from "react";
import "./PrivacyPolicy.scss";
import FrontHeader from "../FrontPage/frontheader";
import FrontFooter from "../FrontPage/frontfooter";

export default function PrivacyPolicy() {
  return (
    <>
      <FrontHeader />
      <div className="static-page mt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-10">
              <div className="static-card pt-3">
                {/* <div className='static-image-box'>
                            <img src='/images/static-icon.svg' alt='iocn'/>
                        </div> */}
                <div className="d-grid gap-0 align-items-center justify-content-center">
                  <img
                    src="/images/privacy-policy.png"
                    alt=""
                    style={{ width: "100px", margin: "0 auto 5px" }}
                  />
                  <h2 className="text-center mb-3">Privacy Policy Terms</h2>
                </div>

                <p>
                  <strong>
                    This Privacy Policy applies to all of the products, services
                    and websites offered by QUICKCHAIN In addition, where more
                    detailed information is needed to explain our privacy
                    practices, we post supplementary privacy notices to describe
                    how particular services process personal information.
                  </strong>
                </p>
                <h3 className="mt-3">
                  Information we collect and how we use them
                </h3>
                <p>
                  We understand that in the course of delivering our numerous
                  products/ services to you via the net, we will come across
                  certain information pertaining to your good self which, to our
                  mind, are purely personal and may even be classified
                  information but for the existing relationship which dictates
                  that you shall either fill them in and/or divulge same in
                  order to be linked to our site. GCBUYING will handle the
                  information in this manner:
                </p>
                <ul>
                  <li>
                    Information you provide
                    <p>
                      When you sign up for any QUICKCHAIN Service or promotion
                      that requires registration, we ask you for personal
                      information (such as your name, email address and an
                      account password). We typically do not store this
                      information and when we do, we maintain the data in
                      encrypted form on secure servers. We may combine the
                      information you submit under your account with information
                      from other third parties in order to provide you with a
                      better experience and to improve the quality of our
                      services. For certain services, we may give you the
                      opportunity to opt out of combining such information.
                    </p>
                  </li>
                  <li>
                    Log Information
                    <p>
                      When you access QUICKCHAIN, our servers automatically
                      record information that your browser sends whenever you
                      visit a website.
                    </p>
                  </li>
                  <li>
                    User communications
                    <p>
                      When you send email or other communications to QUICKCHAIN
                      we may retain those communications in order to process
                      your inquiries, respond to your requests and improve our
                      services.
                    </p>
                  </li>
                </ul>
                <h3>Other sites</h3>
                <p>
                  This Privacy Policy applies to QUICKCHAIN only. We do not
                  exercise control over the sites displayed or linked from
                  within our various services. These other sites may place their
                  own cookies or other files on your computer, collect data or
                  solicit personal information from you. QUICKCHAIN processes
                  personal information for the purposes described.
                </p>
                <h3>Data policy</h3>
                <p>
                  QUICKCHAIN Data Protection Policy refers to our commitment to
                  treat information of employees, customers, stakeholders and
                  other interested parties with the utmost care and
                  confidentiality. With this policy, we ensure that we gather,
                  store and handle data fairly, transparently and with respect
                  towards individual rights.
                </p>
                <h3>Scope</h3>
                <p>
                  This policy refers to all parties (employees, customers,
                  suppliers, Merchants etc.) who provide any amount of
                  information to us. Who is covered under the Data Protection
                  Policy? Employees of our company and its subsidiaries must
                  follow this policy. Contractors, consultants, partners and any
                  other external entity are also covered. Generally, our policy
                  refers to anyone we collaborate with or acts on our behalf and
                  may need occasional access to data.
                </p>
                <h3>Terms</h3>
                <p>
                  When a gift card is sent on the app, you have agreed to sell
                  the gift card and if we deem it fit that this gift card
                  satisfies the exchange then your wallet is credited for
                  withdrawal upon discretion. Most withdrawals are instant but
                  some may be subject to further checks and verifications. Most
                  gift card trades are instant but occasionally very few gift
                  cards may require a bit extra time. Gift cards are not 1:1 and
                  the best price is always given at the time. Parallel rate is
                  used and every sale is completed as quickly as possible. Gift
                  cards are given the best rate possible depending on the gift
                  card, some have better prices than others.
                </p>
                <p>
                  We maintain this app as a service to our customers, and by
                  using our app you are agreeing to comply with and be bound by
                  the following terms of use. Please review the following terms
                  and conditions carefully. If you do not agree to the terms and
                  conditions, you should not obtain goods services or products
                  from this site or utilize this site to sell gift cards, trade
                  gift cards, or create an account.
                </p>
                <p>
                  The term "gift card(s)" or "card(s)" when used on this site
                  refers to physical picture of the cards, paper gift
                  certificates, gift card numbers with or without pin numbers in
                  a digital form, whether originally purchased for personal use
                  or for gifts, issued as a refund for returned merchandise (or
                  for any reason) in the form of a merchandise credit, or any
                  combination of these options, which at the time of sale, are
                  accepted by the issuing retailer in the form they are sold by
                  QUICKCHAIN.
                </p>
                <p>
                  Editing, Deleting and Modification. We reserve the right in
                  our sole discretion to edit or delete any documents,
                  information or other content appearing on the Site, including
                  this Agreement, without further notice to users of the Site.
                </p>
                <p>
                  Use of Information. We reserve the right, and you authorize
                  us, to the use and assignment of all information regarding
                  Site uses by you and all information provided by you in any
                  manner consistent with our Privacy Policy.
                </p>
                <h3>Privacy Policy</h3>
                <p>
                  Our Privacy Policy may change from time to time and is a part
                  of this Agreement
                </p>
                <h3>Refund Policy</h3>
                <p>
                  Due to the nature of our online platform, and the products
                  listed, we QUICKCHAIN have a strict no refund policy for any
                  product. No product shall be able to be returned for a like
                  item, or for a cash refund. Additionally, orders cannot be
                  canceled once the order has been placed.
                </p>
                <h3>Right To Refuse An Order</h3>
                <p>
                  We reserves the right to refuse any order placed.
                  Additionally, if there was a mistake or change in the price or
                  rate of an order or gift card applied, we reserves the right
                  to update to the current rate without prior notice as prices
                  on the app are subject to fluctuations hence any needed rate
                  update would be done affecting the final price.
                </p>
                <p>
                  Market rate for currencies used will be parallel market rate
                  as opposed to the standard rate as this is the rate at which
                  forex is obtained. QUICKCHAIN is not liable in the event that
                  the issuing merchant of the gift card declines to accept it
                  upon declaring bankruptcy, or because of changes to its gift
                  card acceptance policy.
                </p>
                <p>
                  You shall use no less than reasonable efforts to maintain the
                  security of your Service credentials. You agree not to
                  transfer your account to an third-party. You shall be solely
                  responsible for use of your credentials and/or your account by
                  any third-party. You must notify QUICKCHAIN upon becoming
                  aware of any breach or suspected breach of the security of
                  your account.
                </p>
                <p>
                  You are solely responsible for any data, text, information,
                  graphics, photos, profiles, audio clips, video clips, links,
                  or other content that you submit, post, display, or otherwise
                  make available on the application.
                </p>
                <p>
                  QUICKCHAIN reserves the right to amend this Agreement at any
                  time. If amendments constitute a material change to the
                  Agreement, to be determined at the sole discretion of
                  QUICKCHAIN, QUICKCHAIN will notify you via e-mail or via a
                  conspicuous notice on the Website.
                </p>
                <p>
                  In order to ensure the integrity of our Service, we reserve
                  the right in our sole discretion to decline to accept a Gift
                  Card at any time, for any or no reason. If the Gift Card is
                  provided to us electronically, we will do nothing.
                </p>
                <h3>Policy elements</h3>
                <p>
                  As part of our operations, we need to obtain and process
                  information. This information includes any offline or online
                  data that makes a person identifiable such as names,
                  addresses, usernames and passwords, digital footprints,
                  photographs, social security numbers, financial data etc. Our
                  company collects this information in a transparent way and
                  only with the full cooperation and knowledge of interested
                  parties. Once this information is available to us, the
                  following rules apply.
                </p>
                <h3>Our data will be:</h3>
                <ul>
                  <li>Accurate and kept up-to-date</li>
                  <li>Collected fairly and for lawful purposes only.</li>
                  <li>
                    Processed by the company within its legal and moral
                    boundaries
                  </li>
                </ul>
                <h3>Our data will not be:</h3>
                <ul>
                  <li>Communicated informally</li>
                  <li>Stored for more than a specified amount of time</li>
                  <li>
                    Transferred to organizations, states or countries that do
                    not have adequate data protection policies
                  </li>
                  <li>
                    Distributed to any party other than the ones agreed upon by
                    the data's owner (exempting legitimate requests from law
                    enforcement authorities)
                  </li>
                </ul>
                <p>
                  In addition to ways of handling the data the company has
                  direct obligations towards people to whom the data belongs.
                  Specifically, we must:
                </p>
                <ul>
                  <li>Let people know which of their data is collected</li>
                  <li>Inform people about how we'll process their data</li>
                  <li>
                    Inform people about who has access to their information
                  </li>
                  <li>
                    Have provisions in cases of lost, corrupted or compromised
                    data
                  </li>
                  <li>
                    Allow people to request that we modify, erase, reduce or
                    correct data contained in our databases
                  </li>
                </ul>
                <p>
                  <b>Action</b>
                </p>
                <h3>To exercise data protection, we're committed to:</h3>
                <ul>
                  <li>Restrict and monitor access to sensitive data</li>
                  <li>
                    Establish data protection practices (document shredding,
                    secure locks, data encryption, frequent backups, access
                    authorization etc.)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FrontFooter />
    </>
  );
}
