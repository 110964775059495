import React from "react";
import "./Footer.scss";
import { NavLink, useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation();

  // Define active paths for each link
  const activePaths = {
    home: ["/user/dashboard"],
    sell: ["/user/cryptoquicksell", "/user/cryptoquicksellwa"],
    wallet: ["/user/wallet", "/user/cashbackwallet", "/user/referralwallet"],
    account: ["/user/account", "/privacy-policy", "/term-of-service", "/help&faq", "/user/kyc", "/user/editprofile"]
  };

  // Function to check if the current path is active for a given key
  const isActive = (paths) => paths.includes(location.pathname);

  return (
    <div className="footer-bottom d-lg-none d-block">
      <div className="footer-card d-flex align-items-center justify-content-between">
        {/* Home */}
        <div className="footer-card-box">
          <NavLink to="/user/dashboard">
            <>
              <img
                src={isActive(activePaths.home) ? "/images/home-active.svg" : "/images/home-foot.svg"}
                alt="Home"
              />
              <p className={`footer-text ${isActive(activePaths.home) ? 'active' : 'inactive'}`}>Home</p>
            </>
          </NavLink>
        </div>

        {/* Sell */}
        <div className="footer-card-box">
          <NavLink to="/user/cryptoquicksell">
            <>
              <img
                src={isActive(activePaths.sell) ? "/images/sell-active.svg" : "/images/sell-foot.svg"}
                alt="Sell"
              />
              <p className={`footer-text ${isActive(activePaths.sell) ? 'active' : 'inactive'}`}>Sell</p>
            </>
          </NavLink>
        </div>

        {/* Wallet */}
        <div className="footer-card-box">
          <NavLink to="/user/wallet">
            <>
              <img
                src={isActive(activePaths.wallet) ? "/images/wallet-active.svg" : "/images/wallet-foot.svg"}
                alt="Wallet"
              />
              <p className={`footer-text ${isActive(activePaths.wallet) ? 'active' : 'inactive'}`}>Wallet</p>
            </>
          </NavLink>
        </div>

        {/* Account */}
        <div className="footer-card-box">
          <NavLink to="/user/account">
            <>
              <img
                src={isActive(activePaths.account) ? "/images/account-active.svg" : "/images/account-foot.svg"}
                alt="Account"
              />
              <p className={`footer-text ${isActive(activePaths.account) ? 'active' : 'inactive'}`}>Account</p>
            </>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
