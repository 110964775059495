import React from 'react'
import './ThemeButton.scss'

export default function ThemeButton(props) {
  return (
    <>
      <button className='theme-btn' disabled = {`${props.loading?"disabled":""}`}>
      {
        props.loading ? (
            <>
              <span className="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>{" "}Loading...
          </>
        ):(<>{props.btnName}</>)
      }
      </button>
    </>
  )
}
