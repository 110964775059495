import React from "react";
import "./Help.scss";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import FrontHeader from "../FrontPage/frontheader";
import FrontFooter from "../FrontPage/frontfooter";

export default function Help() {
  return (
    <>
      <FrontHeader />
      <div className="static-page mt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-10">
              <div className="static-card pt-3">
                {/* <div className='static-image-box'>
                            <img src='/images/static-icon.svg' alt='iocn'/>
                        </div> */}
                <div className="faq-box">
                  <div className="d-grid gap-0 align-items-center justify-content-center">
                  <img src="/images/faq-1.png" alt="" style={{width:"100px", margin: "0 auto 5px"}}/>
                  <h2 className="text-center mb-2">Frequently Asked Questions</h2>
                  </div>
                  <div className="faq-start">
                    <div className="row justify-content-center">
                      <div className="col-lg-11">
                        <Accordion className="mt-4">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              1. How long does it take to receive my payment?
                            </Accordion.Header>
                            <Accordion.Body>
                              Instant payment at the click of a button!
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              2. Are there any fees for selling crypto?
                            </Accordion.Header>
                            <Accordion.Body>Zero fees !</Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              3. Is my data safe on this platform?
                            </Accordion.Header>
                            <Accordion.Body>
                              Your data and transactions are 100% secure by
                              enterprise grade standards!
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <div className="mt-5 text-lg-center">
                          <Link className="btn-cat" to="/login">
                            Still Have Questions? Contact Support
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FrontFooter />
    </>
  );
}
