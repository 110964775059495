import React from "react";
import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./WalletNotice.scss";
import ThemeButton from "../ThemeButton/ThemeButton";
import WalletAddress from "../WalletAddress/WalletAddress";

export default function WalletNotice({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

 
  return (
    <>
      <span onClick={handleShow}>
        <ThemeButton btnName="Get Wallet Address" />
      </span>
      <Offcanvas
        className="walletnoticeoffcanvas"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header
          closeButton
          className="mt-2 pt-2 pb-0"
        ></Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
              <div className="walletnoticeinfo-box text-white">
                <h2 className="text-center mt-1">Notice</h2>
                <div>
                  <img
                    className="notice-img"
                    src="/images/Notice.svg"
                    alt="notice"
                  />
                </div>
                <div className="status-card p-3">
                  <p className="text-center fs-6">
                   {name?name.crypto_notice:''}
                  </p>
                </div>
              </div>
              <div className="d-flex gap-0 gap-lg-2 flex-column flex-lg-row">
                <div className="w-100">
                  <button className="btn-normal" onClick={handleClose}>I do not accept</button>
                </div>
                <div className="w-100">
                  {["bottom"].map((placement, idx) => (
                    <WalletAddress
                      key={idx}
                      placement={placement}
                      name={name}
                      button={`I ACCEPT`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
