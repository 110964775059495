import React from 'react'
import { useCallback } from 'react';

// @ts-ignore
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const CoinsChart = () => {
    // Function to generate data points
    const generateDataPoints = useCallback((noOfDps) => {
        let xVal = 1, yVal = 100;
        const dps = [];
        for (let i = 0; i < noOfDps; i++) {
          yVal = yVal + Math.round(5 + Math.random() * (-5 - 5));
          dps.push({ x: xVal, y: yVal });
          xVal++;
        }
        return dps;
      }, []);

  // Chart options
  const options = {
    theme: "dark1", // "light1", "light2", "dark1", "dark2"
    animationEnabled: true,
    zoomEnabled: true,
    title: {
      text: "",
    },
    backgroundColor: "transparent",
    axisX: {
        gridThickness: 0,
        lineThickness: 0,
        tickLength: 0,  
        labelFormatter: () => "", // Hides X-axis labels
      },
      axisY: {
        gridThickness: 0,
        lineThickness: 0,
        tickLength: 0,  
        labelFormatter: () => "", // Hides Y-axis labels
      },
    data: [
      {
        type: "area",
        markerSize: 0,
        color: "#990e51ab",
        fillOpacity: 0.7,
        // toolTipContent: null,  Hides tooltip for data points
        // indexLabel: "",  Hides data point numbers on the graph
        dataPoints: generateDataPoints(70),
      },
    ],
  };

  return (
    <>
      <div style={{ height: "150px"}}>
      <CanvasJSChart options={options} />
    </div>
    </>
    
  );
};


  
  export default CoinsChart;