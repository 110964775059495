import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./KycRequest.scss";
import { Link } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import ThemeButton from "../../ThemeButton/ThemeButton";

export default function KycRequest({imageSubmit}) {
    const [show, setShow] = useState(imageSubmit);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <Button className="w-100 d-none" onClick={handleShow}>
          Successfully
        </Button>
  
        <Modal
          show={show}
          onHide={handleClose}
          className="kycr-popup"
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div>
          <Modal.Header closeButton className="border-0 pb-0">
            <Modal.Title className="text-center"></Modal.Title>
          </Modal.Header>
          <div className="d-flex d-lg-block justify-content-center h-100">
          <Modal.Body className="pt-2 pb-4">
            <div className="success-info">
                <img src="/images/confetti.gif" alt="success-icon"/>
              <h2 className="text-center">KYC Verification request Sent</h2>
              <div className="text-center mb-2">
                <p>This may take upto 24 hours</p>
              </div>
              <button className='theme-btn' onClick={handleClose}>
                Continue
              </button>
            </div>
          </Modal.Body>
          </div>
          </div>
        </Modal>
      </>
    );
  }
