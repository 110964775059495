import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./RequestWithdrawal.scss";
import { Link } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import ThemeButton from "../../ThemeButton/ThemeButton";
import Success from "../Success/Success";

export default function RequestWithdrawal(props) {
  const [show, setShow] = useState(props.show1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // phone 
  const handleChangePhone = (e) => {
    const inputValue = e.target.value;
    // Allow only numbers
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    props.setAmount(numericValue);
  };

  return (
    <>
      <Button className="btn btn-theme w-100" onClick={handleShow}>
        REQUEST WITHDRAW
      </Button>
      {
        props.successShow?<>
         <Success setSuccessShow={props.setSuccessShow} setShow={setShow} amount={props.amount} setPassword={props.setPassword} setAmount = {props.setAmount} setShow1={props.setShow1} img="confetti.gif"/>
        </>:<>
         <Modal
          show={show}
          onHide={handleClose}
          className="rw-popup"
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton className="border-0 pb-0">
            <Modal.Title className="text-center"></Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-2">
            <div className="rw-info">
              <h2 className="text-center mb-4">Withdraw Balance</h2>
              <div className="rw-form mb-4">
                <Form onSubmit={props.handleRequest}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Amount</Form.Label>
                    <Form.Control type="Text" placeholder="Enter Your Amount" value={props.amount}
                    onChange={handleChangePhone}
                    // onChange={(e)=>props.setAmount(e.target.value)}
                     />
                  </Form.Group>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter Your Password" value={props.password} onChange={(e)=>props.setPassword(e.target.value)}  />
                  </Form.Group>
                  <ThemeButton btnName="Submit" loading={props.loading}/>
                </Form>
              </div>
              <div className="text-center">
                <p>By continuing, you agree to accept our</p>
                <span>
                  <Link to="/privacy-policy"><strong>Privacy policy</strong></Link>
                </span>{" "}
                &{" "}
                <span>
                  <Link to="/term-of-service"><strong>Terms of Services</strong></Link>
                </span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        </>
      }
     
    </>
  );
}
