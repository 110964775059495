import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Dashboard.scss";
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import { LineWave } from "react-loader-spinner";

const Dashboard = ({ children }) => {
  const [isOpen, setIsOpen] = useState(window.innerWidth >= 992); // Sidebar state
  const [headerMinHeight, setHeaderMinHeight] = useState("auto"); // Default min-height for mobile
  const location = useLocation(); // To track the current route
  const navigate = useNavigate(); // To navigate programmatically
  const isActive = (paths) => paths.includes(location.pathname);
  const profileimage = localStorage.getItem("profileimage");
  const menulist = JSON.parse(localStorage.getItem("sidemenu")) ?? [];

  const [loader, setLoader] = useState(false);

  // Function to toggle sidebar (for mobile)
  const toggleSidebar = () => {
    if (window.innerWidth < 992) {
      setIsOpen((prev) => !prev);
    }
  };
  const auth = JSON.parse(localStorage.getItem("user"));

  // Handle window resizing (for mobile view)
  const handleResize = () => {
    if (window.innerWidth >= 992) {
      setIsOpen(true); // Show sidebar on larger screens
    } else {
      setIsOpen(false); // Hide sidebar on smaller screens
    }

    // After resizing, set the mobile header min-height again
    setMobileHeaderMinHeight();
  };

  // Set min-height based on page and mobile view
  const setMobileHeaderMinHeight = () => {
    if (window.innerWidth < 575) {
      // Apply min-height changes only for mobile devices
      switch (location.pathname) {
        case "/user/dashboard":
          setHeaderMinHeight("330px"); // Home page min-height
          break;
        case "/user/kyc":
          setHeaderMinHeight("190px"); // KYC page min-height
          break;
        case "/user/transcation":
          setHeaderMinHeight("190px"); // Contact page min-height
          break;
        case "/user/cryptoquicksell":
          setHeaderMinHeight("190px"); // Crypto quicksell page min-height
          break;
        case "/user/cryptoquicksellwa":
          setHeaderMinHeight("190px"); // Crypto quicksell page min-height
          break;
        case "/user/wallet":
          setHeaderMinHeight("335px"); // Wallet page min-height
          break;
        case "/user/cashbackwallet":
          setHeaderMinHeight("300px"); // Cashback Wallet page min-height
          break;
        case "/user/referralwallet":
          setHeaderMinHeight("270px"); // Referral Wallet page min-height
          break;
        case "/user/account":
          setHeaderMinHeight("330px"); // Account page min-height
          break;
        case "/user/editprofile":
          setHeaderMinHeight("330px"); // Edit profile page min-height
          break;
        case "/user/notification":
          setHeaderMinHeight("190px"); // Notification page min-height
          break;
        default:
          setHeaderMinHeight("auto"); // Default page min-height
          break;
      }
    } else {
      // Reset min-height to a default value when not on mobile
      setHeaderMinHeight("auto");
    }
  };

  // logout
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    if (menulist.length > 0) {
      setLoader(false);
    } else {
      setLoader(true);
      getMenuList();
    }

    // Initialize mobile header height when the page loads and on route change
    setMobileHeaderMinHeight();

    // Listen for resize events
    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location]); // Run when location (route) changes

  // Determine if the back button should be shown based on the route
  const shouldShowBackButton = [
    "/user/kyc",
    "/user/cryptoquicksellwa",
    "/user/editprofile",
  ].includes(location.pathname);
  if (profileimage == "null" || profileimage == "") {
    var profileImg = "/images/profile.svg";
  } else {
    var profileImg = profileimage;
  }


  const getMenuList = async ()=>{
    try {
      setLoader(true)
      const data = "";
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.getMenu(token)
      localStorage.setItem('sidemenu', JSON.stringify(response.data.data));
      setLoader(false)

    } catch (error) {
      if (error.response) {
        console.log(error.data.data.message)
        setLoader(false)

      } else if (error.request) { 
        console.error('Error request:', error.request);
        setLoader(false)

      } else {
        console.error('Error message:', error.message);
      }
    }
  }

  return (
    <div className="d-flex">
      {/* Sidebar */}
      <nav
        className={`dashboard pt-4 pt-lg-0 ${
          isOpen ? "collapse show" : "collapse"
        }`}
      >
        <div className="logo d-flex d-lg-block justify-content-center w-100">
          <Link className="d-flex align-items-center" to="/user/dashboard">
            <img src="/images/logo.png" alt="Logo" />
            <h2 class="text-center text-white fs-4 mt-1">Quickchain</h2>
          </Link>
          <button className="btnn-close d-lg-none" onClick={toggleSidebar}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <ul className="nav flex-column">
          {loader ? (
            <>
              <LineWave
                visible={true}
                height="100"
                width="100"
                color="#4fa94d"
                ariaLabel="line-wave-loading"
                wrapperStyle={{}}
                wrapperClass=""
                firstLineColor=""
                middleLineColor=""
                lastLineColor=""
              />
            </>
          ) : (
            <>
              {menulist.length > 0 ? (
                <>
                  {menulist.map((item) => (
                    <>
                      {item.id == 1 ? (
                        <>
                          <li className="nav-item">
                            <Link
                              to="/user/dashboard"
                              className={`nav-link ${
                                isActive(["/user/dashboard"]) ? "active" : ""
                              }`}
                            >
                              <i className="fa-solid fa-gauge" />
                              {item.menuName}
                            </Link>
                          </li>
                        </>
                      ) : item.id == 2 ? (
                        <>
                          <li className="nav-item">
                            <Link
                              to="/user/cryptoquicksell"
                              className={`nav-link ${
                                isActive([
                                  "/user/cryptoquicksell",
                                  "/user/cryptoquicksellwa",
                                ])
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <i className="fa-solid fa-wallet" />
                              {item.menuName}
                            </Link>
                          </li>
                        </>
                      ) : item.id == 3 ? (
                        <>
                          <li className="nav-item">
                            <Link
                              to="/user/transcation"
                              className={`nav-link ${
                                isActive(["/user/transcation"]) ? "active" : ""
                              }`}
                            >
                              <i className="fa-solid fa-credit-card" />
                              {item.menuName}
                            </Link>
                          </li>
                        </>
                      ) : item.id == 4 ? (
                        <>
                          <li className="nav-item">
                            <Link
                              to="/user/wallet"
                              className={`nav-link ${
                                isActive(["/user/wallet"]) ? "active" : ""
                              }`}
                            >
                              <i className="fa-solid fa-wallet" />
                              {item.menuName}
                            </Link>
                          </li>
                        </>
                      ) : item.id == 5 ? (
                        <>
                          <li className="nav-item">
                            <Link
                              to="/user/cashbackwallet"
                              className={`nav-link ${
                                isActive(["/user/cashbackwallet"])
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <i className="fa-solid fa-money-bill" />
                              {item.menuName}
                            </Link>
                          </li>
                        </>
                      ) : item.id == 6 ? (
                        <>
                          <li className="nav-item">
                            <Link
                              to="/user/referralwallet"
                              className={`nav-link ${
                                isActive(["/user/referralwallet"])
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <i className="fa-solid fa-wallet" />
                              {item.menuName}
                            </Link>
                          </li>
                        </>
                      ) : item.id == 7 ? (
                        <>
                          <li className="nav-item">
                            <Link
                              to="/user/notification"
                              className={`nav-link ${
                                isActive(["/user/notification"]) ? "active" : ""
                              }`}
                            >
                              <i className="fa-solid fa-bell" />
                              {item.menuName}
                            </Link>
                          </li>
                        </>
                      ) : item.id == 8 ? (
                        <>
                          <li className="nav-item">
                            <Link
                              to="/user/account"
                              className={`nav-link ${
                                isActive([
                                  "/user/account",
                                  "/user/kyc",
                                  "/user/editprofile",
                                  "/privacy-policy",
                                  "/term-of-service",
                                  "/help&faq",
                                ])
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <i className="fa-solid fa-circle-user" />
                              {item.menuName}
                            </Link>
                          </li>
                        </>
                      ) : item.id == 9 ? (
                        <>
                          <li className="nav-item">
                            <Link
                              to="/login"
                              className="nav-link"
                              onClick={logout}
                            >
                              <i className="fa-solid fa-right-from-bracket" />{" "}
                              Logout
                            </Link>
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </>
              ) : (
                ""
              )}
            </>
          )}
        </ul>
      </nav>

      {/* Overlay */}
      {isOpen && <div className="overlay" onClick={toggleSidebar}></div>}

      {/* Main Content */}
      <main className="flex-fill position-relative">
        {/* Header with dynamic min-height */}
        <header
          className="headerdash d-flex justify-content-between align-items-start align-items-lg-center crypto-header position-relative ml-250"
          style={{ minHeight: headerMinHeight }} // Apply dynamic min-height
        >
          <div className="bg-overlay-header d-sm-none d-block"></div>
          <div className="z-2">
            {/* Show Back Button or Default Header Content */}
            {shouldShowBackButton ? (
              <button
                className="btn btn-primary btn-back z-2"
                onClick={() => navigate(-1)} // Navigate to previous page
              >
                <i className="fa-solid fa-arrow-left text-white fs-5 mt-1"></i>
              </button>
            ) : (
              <>
                <button
                  className="btn btn-primary btn-menu z-2 d-lg-none"
                  onClick={toggleSidebar}
                >
                  <img src="/images/menenu.svg" alt="Menu" />
                </button>
                <span className="mx-2">
                  Welcome, {auth ? auth.data.userDetails.firstName : ""}{" "}
                  {auth ? auth.data.userDetails.lastName : ""}
                </span>
              </>
            )}
          </div>
          <div className="d-flex gap-4 align-items-center z-2 notifiation-bell">
            <Link
              to="/user/notification"
              className={`mt-1 ${
                isActive(["/user/notification"]) ? "active-bell" : ""
              }`}
            >
              <i className="fa-solid fa-bell" id="notifications" />
            </Link>
            <Link to="/user/account" className="nav-link">
              <img className="header-profile" src={profileImg} alt="Profile" />
            </Link>
          </div>
        </header>
        {children}
      </main>
    </div>
  );
};

export default Dashboard;
