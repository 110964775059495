import React from 'react'
import "./FrontPage.scss";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

export default function FrontHeader() {
  return (
    <>
      <header className="front-nav">
        <Navbar expand="lg" className="bg-body-tertiary w-100">
          <Container>
            <Navbar.Brand>
              <Link className="navbar-brand" to="/">
                <img src="/images/logo.png" alt="logo" />
                <span className="text-white">QUICKCHAIN</span>
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-lg-auto">
                <ul className="navbar-nav ms-lg-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link
                      className="nav-link active"
                      aria-current="page"
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/help&faq">
                      FAQs
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/term-of-service">
                      Term & Service
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#testimonials">
                      Testimonials
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#contact-us">
                      Contact Us
                    </a>
                  </li>
                  <li className="nav-item mb-3 mb-lg-0">
                    <Link className="nav-link login" to="/Login">
                      Login
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link signup" to="/sign-up">
                      Sign Up
                    </Link>
                  </li>
                </ul>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  )
}
