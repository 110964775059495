import React from "react";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import Offcanvas from "react-bootstrap/Offcanvas";
import './DeleteAccount.scss';
import ThemeButton from "../ThemeButton/ThemeButton";
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import { useNavigate } from "react-router-dom";

export default function DeleteAccount({ name, ...props }) {
  const [show, setShow] = useState(false);
  const auth = JSON.parse(localStorage.getItem('user'))
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false)

  const deleteAccount = async () =>{
    try {
      setLoading(true)
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.deleteAccount(token)
      localStorage.removeItem('user');
      navigate('/')
      setLoading(false)

    } catch (error) {
      if (error.response) {
        console.log(error.data.data.message)
        setLoading(false)

      } else if (error.request) { 
        console.error('Error request:', error.request);
        setLoading(false)

      } else {
        console.error('Error message:', error.message);
      }
    }
  }

  const cancel = () =>{
    setShow(false)
  }
  return (
    <>
      <span onClick={handleShow}>
        <div className="delete-account text-danger text-center">
        <span className="fs-6"><i class="fa-solid fa-trash"></i> Request Delete Account</span>
        </div>
      </span>
      <Offcanvas
        className="walletnoticeoffcanvas"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header
          className="mt-2 pt-2 pb-0"
        ></Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
              <div className="walletnoticeinfo-box text-white">
                <h2 className="text-center mt-1">{auth?auth.data.userDetails.firstName:""} {auth?auth.data.userDetails.lastName:""}</h2>
                <div>
                  <img
                    className="notice-img"
                    src="/images/delete.svg"
                    alt="notice"
                  />
                </div>
                <div className="status-card p-3">
                  <p className="text-center fs-6">
                    Are you sure want to permanently delete your QuickChain account?
                    <span className="d-block mt-3 fs-6 text-uppercase">This Action cannot be undone.</span>
                  </p>
                  <Form>
                      <Form.Group
                        className="mb-0"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="password"
                          placeholder="QuickChain Password"
                        />
                      </Form.Group>
                    </Form>
                </div>
              </div>
              <div className="d-flex gap-0 gap-lg-2 flex-column flex-lg-row">
                <div className="w-100">
                  <button className="btn-normal" onClick={cancel}>Cencel</button>
                </div>
                <div className="w-100" onClick={deleteAccount}>
                    <ThemeButton btnName="Delete Account" loading={loading}/>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

