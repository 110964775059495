import React, {useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dashboard from "../Dashboard/Dashboard";
import Footer from "../Footer/Footer";
import "./CryptoSell.scss";
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import { DNA } from 'react-loader-spinner'
import BlankCard from '../BlankCard/BlankCard';
import CoinsChart from "../CoinsChart/CoinsChart1";

export default function CryptoSell() {

 const auth = JSON.parse(localStorage.getItem('user'));
 const [list,setList] = useState([]);
 const [searchTerm, setSearchTerm] = useState('');
 const [loader,setLoader] = useState(false);
 const [coin,setCoin] = useState();
 
 useEffect(()=>{
  getVaultCryptList();
  getCoin()
 },[])
 console.log(coin)
// get crypto list
const getCoin = async ()=>{
  try {
   
    const response = await LoginSignService.getcoinprice()
    var datas = response.data['prices'];
    var datas1 = response.data['total_volumes'];
    localStorage.setItem('coinsprice',JSON.stringify(datas));
    localStorage.setItem('coinsval',JSON.stringify(datas1));
    // console.log(datas);
 
  } catch (error) {
    if (error.response) {
      console.log(error.data.data.message)
    } else if (error.request) { 
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
  }
}

 // get crypto list
 const getVaultCryptList = async ()=>{
      setLoader(true);
    try {
      const token = (auth.data.item.access_token); 
      const response = await LoginSignService.getCryptoList(token)
      setList(response.data.data)
      setLoader(false);
    } catch (error) {
      if (error.response) {
        console.log(error.data.data.message)
        setLoader(false);
      } else if (error.request) { 
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
 }

const filteredData = list.filter(item =>
  item.crypto_name.toLowerCase().includes(searchTerm.toLowerCase())
);

  return (
    <Dashboard>
      <div className="main-content crytoquicksell ml-250">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9">
              <div className="profile-info-card pb-0">
                <div className="profile-completion d-flex align-items-end justify-content-between">
                  <div className="d-flex align-items-center gap-3">
                    <div>
                      <h1 className="fs-3">Crypto Quick Sell</h1>
                      <span className="fs-6 fw-lighter">
                        Select a crypto to generate your dedicated address
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="crytosellbox mt-0 mt-lg-3">
                <div className="row justify-content-end">
                  <div className="col-lg-5 col-xxl-4">
                    <div className="cryptosearch mb-3">
                    <input type="text" id="box" placeholder="Search Crypto" className="search__box"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <i className="fas fa-search search__icon" id="icon" onclick="toggleShow()"></i>
                    </div>
                  </div>
                </div>
                
                <div className="row g-3 py-4">
                  {loader?(<><DNA
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="dna-loading"
                  wrapperStyle={{}}
                  wrapperClass="dna-wrapper"
                /></>):(<>
                  {
                    filteredData.length>0?(<>
                    {
                      filteredData.map((item,index)=>(
                        <>
                            <div className="col-6 col-sm-6 col-md-4 col-lg-4">
                              <Link to="/user/cryptoquicksellwa" state={{item}}>
                                <div className="card-crytosell">
                                  <div className="d-lg-flex d-grid aling-items-center gap-2 px-3 pt-4">
                                    <img src={item.crypto_icon} alt="bitcoin" />
                                    <div>
                                      <h3>{item.crypto_code}</h3>
                                      <h3>NGN {item.naira_rate}</h3>
                                      <p>{item.crypto_name}</p>
                                    </div>
                                    <div className="arrow-box text-end flex-grow-1">
                                      <i class="fa-solid fa-angles-right"></i>
                                    </div>
                                  </div>
                                  <div className="cqs-graph">
                                    {/* <svg
                                      viewBox="0 0 128 62"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M14.5 19.0005C10.1 17.4005 3.33333 24.0005 0.5 27.5005L-3.5 38.5V68.5L135 67V8L127.5 11.5009C119 14.5009 107.5 0.000935942 101 0.500936C94.5 1.00094 89 13.0007 82.5 16.5009C76 20.0012 75.5 16.0007 62 13.0007C48.5 10.0007 41.5 32.0007 35 32.5007C28.5 33.0007 20 21.0005 14.5 19.0005Z"
                                        fill="url(#paint0_linear_51495_6254)"
                                      />
                                      <defs>
                                        <linearGradient
                                          id="paint0_linear_51495_6254"
                                          x1="65.75"
                                          y1="0.488281"
                                          x2="65.75"
                                          y2="68.5"
                                          gradientUnits="userSpaceOnUse"
                                        >
                                          <stop stop-color="#1A30FF" />
                                          <stop
                                            offset="1"
                                            stop-color="#2F1DFF"
                                            stop-opacity="0"
                                          />
                                        </linearGradient>
                                      </defs>
                                    </svg> */}
                                   
                                      <CoinsChart />
                                      
                                    
                                  </div>
                                </div>
                              </Link>
                            </div>
                        </>
                      ))
                    }
                    </>):<>
                    <BlankCard/>
                    </>
                  }
                </>)}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Dashboard>
  );
}
