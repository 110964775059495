import React from "react";
import { useState,useRef } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./CryptoHistory.scss";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useScreenshot } from 'use-react-screenshot';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';

export default function CryproHistory({ name,crypto_currency,transactionID,network,date,time,status,received,nairavalue,usdvalue,trandingrate, ...props }) {
  const [show, setShow] = useState(false);

  const shareUrl = 'tick.gif'; // URL you want to share
  const title = 'Check out this amazing website!'; // Title for the shared content

  const ref = useRef(null);
  const [image, takeScreenshot] = useScreenshot();

  // const getImage = () => {
  //   takeScreenshot(ref.current).then((image) => {
  //     console.log(image);
  //   });
  // };

  const transactionid = `---Transaction Details---\n TransactionID - ${transactionID}\n Currency - ${crypto_currency} \n Trading Rate - NGN ${trandingrate} \n Naira Value - ${nairavalue} \n Recieved - ${received} \n USD Value - $${usdvalue} \n Network - ${network} \n Date - ${date} \n Time - ${time} `;
  const message = `${transactionid}`;
  const encodedMessage = encodeURIComponent(message); // Encode the message for URL
  const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedMessage}`;
 
  const downloadImage = () => {
    if (image) {
      const link = document.createElement('a');
      link.href = image;
      link.download = 'screenshot.png';
      link.click();
    }
  };

  

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Text Copy
  const handleCopy = () => {
    toast('Text copied to clipboard!');

  };
  return (
    <>
      <Button variant="primary" onClick={handleShow} className="chinfo p-0">
        <img src="/images/info-icon.svg" alt="" />
      </Button>
      <Offcanvas
        className="choffcanvas"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton className="mt-2 pt-2 pb-0"></Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-6">
            <div className="walletinfo-box text-white">
            <div className="d-flex info-box-share mt-4 mt-lg-0">
            <h2 className="text-center mt-1">Transcation Details</h2>
            <a href={whatsappUrl} className="Receipt text-white" target="_blank" rel="">
            <img src="/images/share.gif" alt="share" style={{width: "30px"}}/>
            </a>
            {/* <button className="Receipt text-white" onClick={shareWhatsup}>
              <i class="fa-solid fa-share"></i>
            </button> */}

            {/* <button className="Receipt text-white" onClick={getImage}>
              <i class="fa-solid fa-share"></i>
            </button> */}
            {/* <FacebookShareButton 
              url={shareUrl} 
              quote={title} 
              hashtag="#YourHashtag"
            >
                <FacebookIcon size={32} round />
            </FacebookShareButton>

            <WhatsappShareButton 
              url={shareUrl} 
              quote={title} 
              hashtag="#YourHashtag"
            >
                <WhatsappIcon size={32} round />
            </WhatsappShareButton> */}


            </div>
            <p className="approved">
              {status }
              <i className="fa-solid fa-arrow-down" />
            </p>
            <div className="status-card my-4" ref={ref}>
              <div className="d-flex justify-content-around py-2 px-4">
                <div className="status-card-one">
                  <div>
                    <span>Transaction ID</span>
                    <p>{transactionID.slice(0, 10)+'...'} 
                      <CopyToClipboard text={transactionID?transactionID:''} onCopy={handleCopy}>
                        <i class="fa-regular fa-copy"></i>
                      </CopyToClipboard> 
                    </p>
                  </div>
                  <div>
                    <span>Trading Rate</span>
                    <p>NGN {trandingrate}</p>
                  </div>
                  <div>
                    <span>Recieved</span>
                    <p>{received}</p>
                  </div>
                  <div>
                    <span>Date</span>
                    <p>{date}</p>
                  </div>
                  <div>
                    <span>Time</span>
                    <p>{time}</p>
                  </div>
                </div>
                <div className="border-end"></div>
                <div className="status-card-one">
                <div>
                    <span>Currency</span>
                    <p>{crypto_currency}</p>
                  </div>
                  <div>
                    <span>Naira Value</span>
                    <p>{nairavalue}</p>
                  </div>
                  <div>
                    <span>USD Value</span>
                    <p>$ {usdvalue}</p>
                  </div>
                  <div>
                    <span>Network</span>
                    <p>{network}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
            </div>
          </div>
          <ToastContainer />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
