import React from "react";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./PasswordReset.scss";
import ThemeButton from "../ThemeButton/ThemeButton";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginSignService from '../../Services/LoginSignService/LoginSignService';
import Success from "../Modals/Success/Success";
import { useNavigate } from "react-router-dom";


export default function PasswordReset({ name, ...props }) {
  const [show, setShow] = useState(false);
  const auth = JSON.parse(localStorage.getItem("user"));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [currentP,setCurrentP] = useState("");
  const [newP,setNewP] = useState("");
  const [confirmP,setConfirmP] = useState("");
  const [loading,setLoading] = useState(false);
  const [error,setError] = useState("");
  const [success,setSuccess] = useState(false);
  let [showNPassword, setShowPassword] = useState(false);
  let [showCPassword, setShowCPassword] = useState(false);
  let [showConPassword, setShowConPassword] = useState(false);
  let navigate = useNavigate();

  const handlePassword = async (e) =>{
    e.preventDefault();
    let data = {new_password:newP,current_password:currentP,confirm_password:confirmP}
    if(validate())
    {
      try {
        setLoading(true);
        const token = (auth.data.item.access_token); 
        const response = await LoginSignService.updatePassword(data,token)
        setCurrentP("")
        setNewP("")
        setConfirmP("")
        setError("")
        setShow(false)
         setSuccess(true)
      //   toast.success("Password has been successfully updated")
      //   setTimeout(() => {
      //     localStorage.clear();
      //     navigate('/');
      // }, 2000);
      } catch (error) {
        if (error.response) {
          setLoading(false);
          
          console.error('Error response:', error.response.data.message);
          toast.error(error.response.data.message)
          // setError(error.response.data.message)
         
        } else if (error.request) { 
          setLoading(false);
          console.error('Error request:', error.request);
        } else {
          setLoading(false);
          console.error('Error message:', error.message);
        }
      }
    }
  }

   // Validate 
   const validate =()=>{
   
    let result = true;
    if(!currentP)
    {
      toast.error("Please enter current password");
      return result = false;
    }
    if(!newP)
    {
      toast.error("Enter new password");
      return result = false;
    }
    if(!confirmP)
    {
      toast.error(`Enter new confirm password`);
      return result = false;
    }
   
    return result;
  }

  // show hide password 
  const handleNPassword = () =>{
    setShowPassword(prev => !prev);
  }
  const handleCPassword = () =>{
    setShowCPassword(prev => !prev);
  }
  const handleConPassword = () =>{
    setShowConPassword(prev => !prev);
  }
  return (
    <>
      <span
        onClick={handleShow}
        className="passwordresetbtn d-flex gap-2 justify-content-between"
      >
        <span className="d-flex gap-2 align-items-center">
          <i class="fa-solid fa-lock"></i>
          <span>Password Reset</span>
        </span>
        <img src="/images/arrow-right.svg" alt="" />
      </span>
      <Offcanvas
        className="passwordresetoffcanvas"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header
          closeButton
          className="mt-2 pt-2 pb-0"
        ></Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-6">
              <div className="passwordresetinfo-box text-white">
                <h2 className="text-center mt-1">Password Reset</h2>
                <p className="approved text-info">
                  Complete below to reset password
                </p>
                <div className="status-card my-4 p-3">
                  <Form onSubmit={handlePassword}>
                    <Form.Group
                      className="mb-3 view_icon"
                      controlId="exampleForm.ControlInput1"
                    >
                      {/* <Form.Label>Current Password</Form.Label> */}
                      <Form.Control
                        type={showCPassword?'text':'password'}
                        placeholder="Current Password"
                        value={currentP}
                        onChange={(e)=>setCurrentP(e.target.value)}
                      />
                      <i class={`fa-solid view_icon_1 ${showCPassword?'fa-eye':'fa-eye-slash'}`} onClick={handleCPassword}></i>
                    </Form.Group>
                    <Form.Group
                      className="mb-3 view_icon"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type={showNPassword?'text':'password'}
                        placeholder="New Password"
                        value={newP}
                        onChange={(e)=>setNewP(e.target.value)}
                        
                      />
                      <i class={`fa-solid view_icon_1 ${showNPassword?'fa-eye':'fa-eye-slash'}`} onClick={handleNPassword}></i>
                      </Form.Group>
                    <Form.Group
                      className="mb-4 view_icon"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type={showConPassword?'text':'password'}
                        placeholder="Confirm Password"
                        value={confirmP}
                        onChange={(e)=>setConfirmP(e.target.value)}
                      />
                      <i class={`fa-solid view_icon_1 ${showConPassword?'fa-eye':'fa-eye-slash'}`} onClick={handleConPassword}></i>

                    </Form.Group>
                    <span className="text-danger">{error}</span>
                    <ThemeButton btnName="Update" loading={loading} />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {
        success?(<>
         <Success  setShow={setShow}
          message = {'Password updated successfully'}
          pageurl={'/user/account'}
          setSuccess={setSuccess}
          img="tick.gif"
          />
        </>):""
      }
      <ToastContainer />
    </>
  );
}
