import { BrowserRouter as Router, Route, Link, Routes, } from "react-router-dom";
import './App.scss';
import Login from "./Components/Login/Login";
import './Fonts/SofiaProRegular.otf';
import SignUp from "./Components/SignUp/SignUp";
import Forgot from "./Components/Forgot/Forgot";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import Home from "./Components/HomeScreen/Home";
import Account from "./Components/Account/Account";
import Wallet from "./Components/Wallet/Wallet";
import CashBackWallet from "./Components/CashBackWallet/CashBackWallet";
import ReferralWallet from "./Components/ReferralWallet/ReferralWallet";
import Transcation from "./Components/Transcation/Transcation";
import Admin from "./Components/Login/Admin";
import KYC from "./Components/KYC/KYC";
import CryptoSell from "./Components/CryptoSell/CryptoSell";
import CryptoSellWA from "./Components/CryptoSell/CryptoSellWA";
import EditProfile from "./Components/EditProfile/EditProfile";
import Notification from "./Components/Notifications/Notification";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import TermService from "./Components/TermService/TermService";
import Help from "./Components/HelpFAQ/Help";
import FrontPage from "./Components/FrontPage/FrontPage";
import CoinsChart from "./Components/CoinsChart/CoinsChart";
import CoinsChart1 from "./Components/CoinsChart/CoinsChart1";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login/>} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="*" element={<Login />} />

          <Route path="/user" element={<Admin />} >
            <Route path="dashboard" element={<Home />} />
            <Route path="transcation" element={<Transcation />} />
            <Route path="wallet" element={<Wallet />} />
            <Route path="cashbackwallet" element={<CashBackWallet />} />
            <Route path="referralwallet" element={<ReferralWallet />} />
            <Route path="account" element={<Account />} />
            <Route path="kyc" element={<KYC />} />
            <Route path="cryptoquicksell" element={<CryptoSell />} />
            <Route path="cryptoquicksellwa" element={<CryptoSellWA />} />
            <Route path="editprofile" element={<EditProfile />} />
            <Route path="notification" element={<Notification />} />
          </Route>

            <Route path="/forgot-password" element={<Forgot />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="term-of-service" element={<TermService />} />
            <Route path="help&faq" element={<Help />} />
            <Route path="/" element={<FrontPage />} />
            <Route path="/coins-chart" element={<CoinsChart />} />
            <Route path="/coins-chart1" element={<CoinsChart1 />} />
        </Routes>
    </Router>
    </>
  );
}

export default App;
